<template>
  <div>
    <v-sheet color="transparent" class="py-5">
      <video class="d-flex rounded-elephant mx-auto" width="100%" controls>
        <source :src="videoUrl" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </v-sheet>
    <v-container>
      <h2 class="text-left" v-html="title"></h2>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "VideoWrapper",
  props: {
    videoUrl: String,
    title: String,
  },
};
</script>

<style scoped></style>
