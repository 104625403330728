<template>
    <h1 v-html="message"></h1>






</template>

<script>
    import ApiService from '../services/api.service';
    export default {
        name: "ConfirmationToken",
        data(){
            return{
                message:'Account wird verifiziert...'
            }
        },
        created(){
            let self=this;
            ApiService.get("auth/confirm-account?token="+this.$route.query.token).then(function (response) {

                self.message=response.data;
                self.$router.push("../login")
            }).catch(function (error) {
                self.message=error
            });

        }
    }
</script>

<style scoped>

</style>