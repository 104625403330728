<template>
  <v-container
    class="position-relative"
    :max-width="$vuetify.display.mdAndUp ? '75%' : '100%'"
  >
    <div class="bg-backgroundShade pa-1 rounded-lg">
      <v-tabs
        v-model="selected"
        active-class="activeTab"
        alignTabs="center"
        color="accent"
        hide-slider
        fixedTabs
        elevation="5"
        density="compact"
        gap="2"
        class="rounded-lg bg-transparent"
      >
        <v-tab
          v-for="item in steps"
          :key="item"
          :value="item"
          class=""
          selectedClass="bg-background"
        >
          {{ item }}
        </v-tab>
      </v-tabs>
    </div>
    <v-window class="transparent" v-model="selected">
      <v-window-item key="2 von 7" value="2 von 7">
        <Two recap />
      </v-window-item>
      <v-window-item key="3 von 7" value="3 von 7">
        <Three recap />
      </v-window-item>
      <v-window-item key="4 von 7" value="4 von 7">
        <Four recap />
      </v-window-item>
      <v-window-item key="5 von 7" value="5 von 7">
        <Five recap />
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script>
import Two from "@/views/Prozesse/OnBoarding/Steps/Two";
import Three from "@/views/Prozesse/OnBoarding/Steps/Three";
import Four from "@/views/Prozesse/OnBoarding/Steps/Four";
import Five from "@/views/Prozesse/OnBoarding/Steps/Five";
export default {
  name: "OnBoardingRecap",
  components: { Five, Four, Three, Two },
  props: {
    id: String,
  },
  created() {
    this.$store.state.processes.onboarding.instanceId = this.id;
  },
  data() {
    return {
      steps: ["2 von 7", "3 von 7", "4 von 7", "5 von 7"],
      selected: "2 von 7",
    };
  },

  methods: {
    checkIfStepIsCompleted(item) {
      console.log(item.substring(0, 1));
      console.log(this.$store.state.processes.onboarding.currentStep);
      return (
        item.substring(0, 1) * 1 >
        this.$store.state.processes.onboarding.currentStep
      );
    },
  },
};
</script>

<style scoped>
.inner-shadow {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}
</style>
