import axios from "axios";
import host from "./host";
import Notification from "@/models/notification";
const API_URL = host + "/api/auth/";

import store from "../store";
import router from "@/router";

class AuthService {
  login(user) {
    return axios.post(API_URL + "signin", {
      email: user.email,
      password: user.password,
    });
  }

  loginCognito(tokenObject) {
    return axios
      .post(API_URL + "verifyOAuth", {
        token: tokenObject.token,
        email: tokenObject.extraEmail,
      })
      .catch(function (error) {
        console.log(error.response);
        if (error.response.status === 423) {
          //TODO Poipup with name to verify and then connect Account
          console.log("The Email is already in use by a normal Account.");
          store.dispatch(
            "notificationsStore/add",
            new Notification(
              "error",
              "Diese Email Adresse wird bereits verwendet",
              false
            )
          );
        }
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    // store.dispatch(
    //   "notificationsStore/add",
    //   new Notification(
    //     "error",
    //     "Sie sind nicht eingeloggt",
    //     false,
    //     null,
    //     "/login",
    //     "Login"
    //   )
    // );
    router.push("/login?from=" + router.currentRoute.value.path);
  }

  register(user) {
    return axios
      .post(API_URL + "signup", {
        name: user.name,
        email: user.email,
        password: user.password,
        image: user.image,
      })
      .catch(function (error) {
        console.log(error.response);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status == 401) {
            store.dispatch(
              "notificationsStore/add",
              new Notification(
                "error",
                "Sie sind nicht eingeloggt",
                false,
                null,
                "/login",
                "Login"
              )
            );
            store.dispatch("auth/logout");
          } else if (error.response.status == 404) {
            store.dispatch("loading/error");
            store.dispatch(
              "notificationsStore/add",
              new Notification("error", "Dieser Name existiert bereits", false)
            );
          } else if (error.response.status == 403) {
            store.dispatch("loading/error");
            store.dispatch(
              "notificationsStore/add",
              new Notification(
                "error",
                "Diese Email Adresse existiert bereits",
                false
              )
            );
          } else if (
            error.response.status == 500 &&
            error.response.data.message
          ) {
            store.dispatch("loading/error");
            store.dispatch(
              "notificationsStore/add",
              new Notification("error", error.response.data.message, false)
            );
          } else if (error.response.data.message) {
            store.dispatch("loading/error");
            store.dispatch(
              "notificationsStore/add",
              new Notification("error", error.response.data.message, false)
            );
          }
        }
      });
  }

  refreshToken(refreshToken) {
    return axios
      .post(API_URL + "refresh", { refreshToken: refreshToken })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }
}

export default new AuthService();
