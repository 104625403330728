<template>
  <div>
    <h4 class="ml-1 mt-10 d-flex">
      <span v-html="title"></span>
      <div v-if="help">
        <v-icon size="small" color="grey" v-tooltip:top="help" class="ml-1"
          >mdi-help-circle-outline</v-icon
        >
      </div>
    </h4>
  </div>
</template>

<script>
export default {
  name: "FormSubheader",
  props: {
    title: String,
    help: String,
  },
};
</script>

<style scoped></style>
