<template>
  <div v-if="projectDataC">
    <v-navigation-drawer
      id="step3"
      color="background"
      mobile
      class="mt-2"
      :class="rail ? '' : 'px-2'"
      location="right"
      :permanent="$vuetify.display.smAndDown ? false : true"
      width="380"
      bottom
      :rail="$vuetify.display.mdAndUp ? rail : false"
      v-model="isExpanded"
      @click="rail = false"
    >
      <template v-slot:prepend>
        <div class="d-flex align-center">
          <v-btn
            :icon="rail ? 'mdi-arrow-left' : 'mdi-arrow-right'"
            flat
            @click.stop="
              $vuetify.display.mdAndUp
                ? (rail = !rail)
                : (isExpanded = !isExpanded)
            "
            class="d-flex justify-center bg-background"
          >
          </v-btn>
          <span class="font-weight-medium ml-6">Details zum Projekt</span>
        </div>
      </template>
      <v-list class="mt-1" v-model:opened="open">
        <v-list-group
          expand-icon=""
          collapse-icon=""
          class="rounded-lg"
          value="projektInfos"
          :class="rail ? 'bg-background' : 'bg-backgroundShade'"
        >
          <template v-slot:activator="{ props }">
            <v-list-item
              flat
              prepend-icon="mdi-clipboard-text-outline"
              title="Projekt Informationen"
              v-bind="props"
            ></v-list-item>
          </template>
          <div class="pa-2">
            <ProjectInfoItem
              :date="projectDataC.estimatedProjectEnd"
              :description="projectDataC.description"
              :projectId="projectDataC.id"
              :admin="isAdmin"
              @update:description="handleDescriptionUpdate"
              @update:date="handleDateUpdate"
            />
          </div>
        </v-list-group>
        <v-list-group
          expand-icon=""
          collapse-icon=""
          value="onboarding"
          class="mt-4 rounded-lg"
          :class="rail ? 'bg-background' : 'bg-backgroundShade'"
        >
          <template v-slot:activator="{ props }">
            <v-list-item
              flat
              prepend-icon="mdi-laptop-account"
              title="Onboarding"
              v-bind="props"
            ></v-list-item>
          </template>
          <div class="pa-2">
            <OnboardingItem
              class="bg-background"
              v-tooltip:bottom="'Onboarding öffnen'"
              v-if="projectDataC.onboardingId"
              :onboardingId="projectDataC.onboardingId"
              :admin="isAdmin"
            />
          </div>
        </v-list-group>
        <v-list-group
          expand-icon=""
          collapse-icon=""
          value="ansprechpartner"
          class="mt-4 rounded-lg"
          :class="rail ? 'bg-background' : 'bg-backgroundShade'"
        >
          <template v-slot:activator="{ props }">
            <v-list-item
              flat
              prepend-icon="mdi-phone-outline"
              title="Ansprechpartner"
              v-bind="props"
            ></v-list-item>
          </template>
          <div class="pa-2 d-flex flex-column ga-2">
            <ContactItem
              v-if="projectDataC.projectContact"
              :userId="projectDataC.projectContact"
              over-headline="Projektabwicklung"
            ></ContactItem>
            <ContactItem
              v-if="projectDataC.salesContact"
              :userId="projectDataC.salesContact"
              over-headline="Vertrieb"
            ></ContactItem>
          </div>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-container>
      <v-row>
        <v-col class="d-flex align-center" cols="12">
          <v-progress-linear
            id="step1"
            :clickable="isAdmin ? true : false"
            :class="{ 'disabled-progress': !isAdmin }"
            @click="saveProject"
            class="px-2 rounded w-100 rounded-lg"
            v-model="projectDataC.progress"
            :color="
              $vuetify.theme.current.dark ? 'accent' : 'rgba(84, 68, 253, 0.6)'
            "
            height="40"
          >
            <template v-slot:default="{ value }">
              <strong style="font-size: 0.8rem"
                >Gesamtprojekt zu {{ Math.ceil(value) }}% abgeschlossen</strong
              >
            </template>
          </v-progress-linear>
          <v-btn
            v-if="isAdmin && projectDataC.progress < 100"
            v-tooltip:top="'Projekt abschließen'"
            icon="mdi-check"
            class="ml-2"
            flat
            variant="tonal"
            @click="finishProject"
            size="small"
            :color="
              $vuetify.theme.current.dark ? 'accent' : 'rgba(84, 68, 253, 0.6)'
            "
          ></v-btn>

          <v-btn
            v-if="$vuetify.display.smAndDown"
            :icon="isExpanded ? 'mdi-arrow-right' : 'mdi-arrow-left'"
            flat
            @click.stop="
              isExpanded = !isExpanded;
              rail = false;
            "
            class="d-flex justify-center bg-background"
          >
          </v-btn>
        </v-col>
        <v-col cols="12">
          <div id="step2" class="d-flex justify-start">
            <v-timeline
              align-top
              truncate-line="end"
              side="end"
              class="justify-start mb-5 w-100"
            >
              <UpdateNew
                v-if="isAdmin"
                @updateProject="updateProject"
                :project-id="projectDataC.id"
              />
              <UpdateItem
                v-for="item of sortedUpdates"
                :key="item.id"
                @updateProject="updateProject"
                :update-item="item"
                :project-id="projectDataC.id"
              />
            </v-timeline>
          </div>
        </v-col>
      </v-row>
      <v-dialog
        class="d-flex align-center align-content-center"
        v-model="projectCompleted"
        max-width="600px"
        height="400px"
      >
        <v-card class="rounded-elephant h-100">
          <v-card-title class="ml-2 text-wrap"
            >Projekt erfolgreich abgeschlossen 🥳
          </v-card-title>
          <v-card-text class="d-flex justify-center align-center">
            <DotLottieVue
              style="width: auto; height: 15rem"
              autoplay
              src="https://lottie.host/7631e0b7-846f-4fdb-84ff-c282c8dc4a99/tArMekKv4x.json"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              rounded="xl"
              block
              color="accent"
              append-icon="mdi-arrow-right"
              to="/admin/projekte"
              >Zur Projektübersicht</v-btn
            ></v-card-actions
          >
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import ContactItem from "@/views/Projekte/ContactItem.vue";
import OnboardingItem from "@/views/Prozesse/OnBoarding/OnboardingItem.vue";
import ProjectInfoItem from "@/views/Projekte/ProjectInfoItem.vue";
import UpdateItem from "@/views/Projekte/UpdateItem";
import UpdateNew from "@/views/Projekte/UpdateNew";
import ApiService from "@/services/api.service";
import { DotLottieVue } from "@lottiefiles/dotlottie-vue";
import Notification from "../../models/notification";
import introJs from "intro.js";

export default {
  name: "ProjectItem",
  components: {
    UpdateNew,
    UpdateItem,
    OnboardingItem,
    ContactItem,
    ProjectInfoItem,
    DotLottieVue,
  },
  props: {
    projectData: Object,
    id: String,
  },
  data() {
    return {
      projectDataC: this.projectData,
      tabs: {
        selected: null,
        items: ["Info", "Prozesse", "Serviceanfragen"],
      },
      ansprechpartner: {
        sales: null,
        project: null,
      },
      isExpanded: true,
      rail: false,
      open: ["projektInfos", "onboarding", "ansprechpartner"],
      projectCompleted: false,
    };
  },
  watch: {
    rail(newVal) {
      if (newVal) {
        this.open = [];
      } else {
        this.open = ["projektInfos", "onboarding", "ansprechpartner"];
      }
    },
    "$store.state.project.selected"(newVal) {
      this.projectDataC = newVal;
      console.log("ProjectData", this.projectDataC);
    },
    projectDataC: {
      immediate: true,
      handler(newValue) {
        if (newValue && this.$store.state.auth.user.firstVisit) {
          this.$nextTick(() => {
            this.startIntro();
          });
        }
      },
    },
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.user.roles.includes("ROLE_ADMIN");
    },
    sortedUpdates() {
      return [...this.projectDataC.updateItems].sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.timestamp) - new Date(a.timestamp);
      });
    },
  },

  async created() {
    if (this.$vuetify.display.smAndDown) {
      this.isExpanded = false;
    }
    await this.getProject();
  },
  methods: {
    finishProject() {
      this.projectDataC.progress = 100;
      this.saveProject();
      this.projectCompleted = true;
    },
    handleDateUpdate(newDate) {
      this.projectDataC.estimatedProjectEnd = newDate;
    },
    handleDescriptionUpdate(newDescription) {
      this.projectDataC.description = newDescription;
    },
    async getProject() {
      try {
        if (this.id) {
          const response = await ApiService.get("project/get/" + this.id);
          this.projectDataC = response.data;
          await this.$store.dispatch(
            "project/getOnboardingOfProject",
            this.projectDataC.onboardingId
          );
        } else {
          await this.$store.dispatch(
            "project/getOnboardingOfProject",
            this.projectDataC.onboardingId
          );
        }
      } catch (error) {
        this.$store.dispatch(
          "notificationsStore/add",
          new Notification(
            "error",
            "Laden der Projektdaten fehlgeschlagen",
            false
          )
        );
      }
    },
    updateProject(project) {
      this.projectDataC.updateItems = project.updateItems;
    },
    async saveProject() {
      if (this.isAdmin) {
        try {
          const response = await ApiService.post(
            "project/update",
            this.projectDataC
          );
          this.updateProject(response.data);
        } catch (error) {
          this.$store.dispatch(
            "notificationsStore/add",
            new Notification(
              "error",
              "Speichern der Projektdaten fehlgeschlagen",
              false
            )
          );
        }
      }
    },
    startIntro() {
      this.$nextTick(() => {
        const intro = introJs();
        intro.setOptions({
          steps: [
            {
              element: "#step1", // Das Ziel-Element
              title: "Projektfortschritt", // Titel des Schritts
              intro: "Hier sehen Sie den Gesamtfortschritt des Projekts.", // Beschreibung des Schritts
            },
            {
              element: "#step2",
              title: "Update Timeline",
              intro:
                "In diesem Bereich finden Sie alle einzelnen Updates in chronologischer Reihenfolge.",
            },
            {
              element: "#step3",
              title: "Projektinformationen",
              intro:
                "In diesem Abschnitt werden nützliche Informationen zum Projekt, sowie Ihr Onboarding und Ihre Ansprechpartner angezeigt.",
            },

            {
              title: "Tour beendet 🎉",
              intro: "Das wars schon. Wir freuen uns auf die Zusammenarbeit!",
            },
          ],
          tooltipClass: "customTooltip",
          buttonClass: "customButton",
          showProgress: false, // Fortschrittsanzeige aktivieren
          showBullets: true, // Keine Navigation-Bullets anzeigen
          exitOnEsc: true, // Schließen mit der Esc-Taste erlauben
          exitOnOverlayClick: false, // Overlay-Klick verhindert Tour-Ende
          disableInteraction: true, // Interaktionen während der Tour deaktivieren
          nextLabel: "Weiter",
          prevLabel: "Zurück",
          doneLabel: "Schließen",
          hidePrev: true,
          overlayOpacity: 0.15,
        });
        intro.start();
      });
    },
  },
};
</script>

<style scoped></style>
