import { createVuetify } from "vuetify";
import colors from "vuetify/util/colors";
import de from "vuetify/lib/locale/de";
import en from "vuetify/lib/locale/en";
import "vuetify/styles";
import { VDateInput } from "vuetify/labs/VDateInput";

const opts = {
  lang: {
    locales: { de, en },
    current: "en",
  },
  components: {
    VDateInput,
  },
  defaults: {
    // global: {
    //   rounded: "lg",
    // },
    VTextField: {
      color: "accent",
      rounded: "lg",
    },
    VTextarea: {
      color: "accent",
      rounded: "lg",
    },
    VCard: {
      rounded: "lg",
    },
    VListGroup: {
      rounded: "lg",
    },
    VListItem: {
      rounded: "lg",
    },
    VBtn: {
      rounded: "lg",
    },
  },
  theme: {
    defaultTheme: "light",
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        colors: {
          primary: "#121E39",
          // primary: "#0047BB",
          secondary: "#0A101F",
          // accent: "#0047BB",
          accent: "#5445FF",
          text: "#F2F2F2",
          background: "#FFFFFF",
          backgroundShade: colors.grey.lighten4,
        },
      },
      dark: {
        colors: {
          primary: "#FFFFFF",
          // primary: "#0047BB",
          secondary: "#0A101F",
          // accent: "#5445FF",
          accent: "#8c84f9",
          text: "#F2F2F2",
          background: "#1e1e1e",
          backgroundShade: "#232323",
        },
      },
    },
  },
};
const vuetify = createVuetify(opts);
export default vuetify;
