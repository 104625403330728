<template>
  <div class="pt-4">
  <label class="">
    <div class="mx-1 mb-1" v-html="title"></div>
    <slot>
    </slot>
  </label>
  </div>
</template>

<script>
export default {
  name: "InputGroup",
  props:{
    title:String
  }
}
</script>

<style scoped>

</style>