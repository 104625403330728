<template>
  <v-alert
    v-for="(notification, index) of notifications"
    :key="index"
    :class="{
      animate__fadeOutRight: notification.animation,
      accent: !notification.type,
    }"
    :type="notification.type"
    class="animate__animated animate__backInUp"
    closable
    max-width="400px"
    style="z-index: 1000000; position: absolute; right: 0; top: 0; margin: 20px"
  >
    <v-row align="center">
      <v-col class="grow">
        <span v-html="notification.text"></span>
        <div v-if="notification.subtext">
          <small v-html="notification.subtext"></small>
        </div>
      </v-col>
      <v-col v-if="notification.action" class="shrink">
        <v-btn color="primary" flat @click="$router.push(notification.action)"
          ><span v-html="notification.buttonText"></span
        ></v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>
<script>
import { no } from "vuetify/locale";

export default {
  name: "AppNotifications",
  computed: {
    no() {
      return no;
    },
    notifications() {
      return this.$store.state.notificationsStore.notifications;
    },
  },
};
</script>
<style>
@media screen and (max-width: 600px) {
}
</style>
