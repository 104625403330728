<template>
  <v-container>
    <VideoWrapper
      :video-url="require('@/assets/onboardingvideos/Onboarding_Teil6.mp4')"
      title="Erwartungshaltung: Kommunikation und Ergebnisse"
    >
    </VideoWrapper>

    <v-row v-if="!recap" class="mt-5">
      <v-col cols="6"
        ><v-btn
          block
          color="backgroundShade"
          prepend-icon="mdi-arrow-left"
          @click="stepBack"
          :loading="$store.state.loading.loadingState"
          >Zurück</v-btn
        ></v-col
      ><v-col cols="6"
        ><v-btn
          block
          append-icon="mdi-arrow-right"
          color="primary"
          :loading="$store.state.loading.loadingState"
          @click="checkStep"
          >Weiter</v-btn
        ></v-col
      ></v-row
    >
  </v-container>
</template>

<script>
import { nextStep, getBack } from "@/services/onboarding.service";
import VideoWrapper from "@/views/Prozesse/OnBoarding/Steps/VideoWrapper";

export default {
  name: "StepSix",
  components: { VideoWrapper },
  methods: {
    stepBack() {
      getBack();
    },
    checkStep() {
      //TODO Go to Server and check step 1 if good increase activeStep
      nextStep().then(() => {
        window.scrollTo(0, 0);

        this.$emit("next");
      });
    },
  },
};
</script>

<style scoped></style>
