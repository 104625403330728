<template>
  <v-dialog v-model="show" persistent fullscreen>
    <v-sheet class="d-flex" style="height: 100vh">
      <div class="d-flex align-center justify-center w-100">
        <v-img
          v-if="!$vuetify.theme.dark"
          class="position-absolute top-0 mt-5"
          width="100px"
          height="100px"
          :src="require('../assets/ElephantNew.png')"
        ></v-img>
        <div class="w-75">
          <div class="text-left d-block mb-10 login-container">
            <h2 class="font-weight-bold">
              Willkommen im Elephant Kundenportal!
            </h2>

            <p class="text-disabled">
              Geben Sie Ihre Zugangsdaten ein, um fortzufahren.
            </p>
          </div>
          <v-alert
            type="success"
            class="my-5"
            color="success"
            v-if="successMessage"
            dismissible
          >
            {{ successMessage }}
          </v-alert>
          <v-form ref="form" v-model="valid">
            <v-text-field
              prepend-inner-icon="mdi-email-outline"
              variant="outlined"
              autofocus
              label="E-Mail"
              type="email"
              v-model="user.email"
              required
              rounded
              class="mb-3 w-100"
            />
            <div class="position-relative">
              <v-text-field
                prepend-inner-icon="mdi-lock-outline"
                label="Passwort"
                v-model="user.password"
                variant="outlined"
                @keypress.enter="handleLogin"
                :rules="passwordRules"
                :append-inner-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                :type="passwordVisible ? 'text' : 'password'"
                @click:append-inner="passwordVisible = !passwordVisible"
                required
                rounded
                class="mb-0 pb-0"
              />
              <div class="d-flex justify-space-between align-center mb-5 pa-0">
                <v-checkbox
                  class="my-0 py-0 d-flex justify-start align-center"
                  label="Angemeldet bleiben"
                  color="accent"
                  v-model="user.rememberMe"
                ></v-checkbox>
                <span
                  @click="$router.push('/u/reset/password/request')"
                  class="text-accent"
                  >Passwort vergessen?</span
                >
              </div>
            </div>
            <v-btn
              class="rounded-elephant"
              :loading="loading"
              block
              color="accent"
              @click="handleLogin"
              size="large"
            >
              <span>Anmelden</span>
              <v-icon class="ml-1">mdi-login</v-icon>
            </v-btn>

            <v-alert
              type="error"
              class="mt-3"
              v-if="message"
              dismissible
              color="red"
            >
              {{ message }}
            </v-alert>
          </v-form>
        </div>
      </div>
      <div
        v-if="$vuetify.display.mdAndUp"
        class="d-flex flex-column align-center justify-center bg-primary w-100 h-100"
      >
        <DotLottieVue
          style="width: 550px; height: 550px"
          autoplay
          loop
          src="https://lottie.host/83cd2fdc-5c39-4ebd-8d1c-c9fb67246b5b/JToK6z7Eo7.json"
        />
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { DotLottieVue } from "@lottiefiles/dotlottie-vue";

export default {
  name: "LoginPage",
  components: {
    DotLottieVue,
  },
  data() {
    return {
      valid: true,

      passwordVisible: false,
      show: true,
      user: {
        email: "",
        password: "",
        rememberMe: false,
      },
      message: "",
      loading: false,
      username: "",
      usernameRules: [
        (v) => !!v || this.$t("login.nameRequired"),
        (v) => (v && v.length <= 20) || this.$t("login.nameLength"),
      ],
      password: "",
      confirmPassword: "",
      passwordRules: [(v) => !!v || this.$t("login.passwordRequired")],
    };
  },
  computed: {
    successMessage() {
      return this.$route.query.m;
    },
    frontendhost() {
      return process.env.VUE_APP_FRONTHOST;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    from() {
      return this.$route.query.from;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;

      if (this.user.email && this.user.password) {
        this.$store
          .dispatch("auth/login", this.user)
          .then(() => {
            if (this.from) location.href = this.from;
            else location.href = "/my/projects";
          })
          .catch(() => {
            this.loading = false;
            this.message = "You entered the wrong email or password";
          });
      }
    },
  },
};
</script>
<style>
.v-checkbox {
  margin: 0;
  padding: 0;
}
@media (max-width: 768px) {
  .login-container {
    margin-top: 6rem;
  }
}
</style>
