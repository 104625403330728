<template>
  <!-- <v-card color="backgroundShade" class="mx-auto" max-width="344" v-if="user">
    <v-list-item three-line>
      <div v-html="overHeadline" class="text-overline mb-4"></div>
      <v-list-item-title
        v-html="user.firstName + ' ' + user.lastName"
        class="text-h5 mb-1 text-capitalize"
      >
      </v-list-item-title>

      <v-list-img>
        <v-img
          height="100%"
          width="100%"
          class="rounded"
          :src="
            user.profilePic
              ? 'data:image/png;base64,' + user.profilePic.file
              : '//ssl.gstatic.com/accounts/ui/avatar_2x.png'
          "
        />
      </v-list-img>
    </v-list-item>

    <v-card-actions>
      <v-btn rounded icon :href="'mailto:' + user.email">
        <v-icon>mdi-email</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card> -->
  <v-card flat color="background" v-if="user">
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-subtitle
          v-text="overHeadline"
          class="text-overline mt-3"
        ></v-card-subtitle>
        <v-card-title class="text-h5 text-capitalize my-0 py-0">
          {{ user.firstName + " " + user.lastName }}
        </v-card-title>

        <v-btn
          class="ml-4"
          variant="plain"
          density="dense"
          icon="mdi-email-outline"
          :href="'mailto:' + user.email"
          v-tooltip="{
            text: 'Email schreiben',
          }"
        >
        </v-btn>
      </div>

      <v-avatar class="ma-3" rounded="lg" size="80">
        <v-img
          :src="
            user.profilePic
              ? 'data:image/png;base64,' + user.profilePic.file
              : '//ssl.gstatic.com/accounts/ui/avatar_2x.png'
          "
        ></v-img>
      </v-avatar>
    </div>
  </v-card>
</template>

<script>
import ApiService from "@/services/api.service";
import Notification from "@/models/notification";

export default {
  name: "ContactItem",
  data() {
    return {
      user: null,
    };
  },
  props: {
    overHeadline: String,
    userId: Number,
  },
  watch: {
    userId(newUserId) {
      this.getUserById(newUserId);
    },
  },
  async created() {
    await this.getUserById(this.userId);
  },
  methods: {
    async getUserById(id) {
      try {
        const response = await ApiService.get("user/get?id=" + id);
        this.user = response.data;
        console.log("User:", this.user);
      } catch (error) {
        this.$store.dispatch(
          "notificationsStore/add",
          new Notification(
            "error",
            "Laden der Kontaktdaten fehlgeschlagen",
            false
          )
        );
      }
    },
  },
};
</script>

<style scoped></style>
