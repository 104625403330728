<template>
  <v-navigation-drawer
    v-model="drawer"
    :permanent="$vuetify.display.mdAndUp ? true : false"
    border="none"
    color="backgroundShade"
  >
    <v-img
      v-if="$vuetify.theme.current.dark"
      class="ma-4 mb-10"
      :src="darkThemeImage"
      @click="$router.push('/my/projects')"
    ></v-img>
    <v-img
      v-else
      @click="$router.push('/my/projects')"
      class="ma-4 mb-10"
      :src="lightThemeImage"
    ></v-img>
    <v-list nav>
      <v-list-item
        v-if="isAdmin()"
        prepend-icon="mdi-shield-account"
        title="Admin Dashboard"
        value="admin-projekte"
        color="accent"
        to="/admin/projekte"
        @click="$vuetify.display.smAndDown ? toggleDrawer() : null"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-sitemap"
        link
        to="/my/projects"
        title="Meine Projekte"
        value="projekte"
        color="accent"
        @click="$vuetify.display.smAndDown ? toggleDrawer() : null"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-tools"
        to="/service"
        link
        title="Service"
        value="service"
        color="accent"
        @click="$vuetify.display.smAndDown ? toggleDrawer() : null"
      ></v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="pa-3">
        <v-btn
          prepend-icon="mdi-logout"
          class="text-left text-red-darken-3"
          variant="text"
          block
          to="/logout"
        >
          Logout
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import lightThemeImage from "@/assets/Elephant Logo lightTheme.png";
import darkThemeImage from "@/assets/Elephant Logo darkTheme.png";

export default {
  name: "SideBar",

  data() {
    return { lightThemeImage, darkThemeImage };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    drawer: {
      get() {
        return this.$store.getters["navigation/isOpen"];
      },
      set() {
        this.$store.dispatch("navigation/toggleDrawer");
      },
    },
  },
  methods: {
    toggleDrawer() {
      this.$store.dispatch("navigation/toggleDrawer");
    },
    isAdmin() {
      try {
        const roles = this.$store.state.auth.user.roles;
        if (!roles) {
          throw new Error("Roles are not defined");
        }
        return roles.includes("ROLE_ADMIN");
      } catch (error) {
        console.error("Error checking admin role:", error.message);
        return false;
      }
    },
  },
};
</script>

<style scoped></style>
