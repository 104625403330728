<template>
  <v-dialog
    v-model="$store.state.processes.onboarding.showFinishedDialog"
    width="500"
  >
    <v-card>
      <v-card-title class="text-h6 primary text-secondary">
        Onboarding abgeschlossen!
      </v-card-title>

      <v-card-text class="py-3">
        <p>
          Vielen Dank, mit Ihren Angaben helfen Sie uns, Ihr Projekt effizient &
          mit bester Qualität abzuschließen.
        </p>
        <p>
          Auf dieser Seite können Sie nun den Projektstand jederzeit
          nachvollziehen.
        </p>
        <p>
          Falls Sie Fragen haben, finden Sie dort auch Ihren direkten
          Anprechpartner.
        </p>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="accent"
          append-icon="mdi-arrow-right"
          text
          @click="$store.state.processes.onboarding.showFinishedDialog = false"
        >
          Let's go
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SuccessDialog",
  watch: {
    "$store.state.processes.onboarding.showFinishedDialog"() {
      if (this.$store.state.processes.onboarding.showFinishedDialog)
        this.start();
    },
  },
  methods: {
    created() {},
    start() {
      this.$confetti.start();
      setTimeout(() => {
        this.stop();
      }, 10000);
    },

    stop() {
      this.$confetti.stop();
    },
  },
};
</script>

<style scoped></style>
