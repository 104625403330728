<template>
  <v-container>
    <VideoWrapper
      title="Datei-Uploads & Tracking mit Google Analytics"
      :video-url="require('@/assets/onboardingvideos/Onboarding_Teil4.mp4')"
    />
    <!--TODO  Link zu Schnittstelle -> SchnittstelleURL attribut in Projekt -->

    <v-sheet class="py-4 px-8 rounded-elephant" elevation="4">
      <h4 class="mb-2">Wir haben eine Schnittstelle für Sie vorbereitet:</h4>
      <p>
        Diese dient als <i>Datei-Austauschplattform</i> zwischen Ihnen und
        Elephant für die <i>gesamte Projektlaufzeit.</i>
      </p>
      <p>
        Bitte laden Sie dort alle <b>nötigen Dateien</b> hoch. Dazu zählen
        <b>Logo</b>, Bilder die wir für Sie auf der neuen Website verwenden
        sollen, fertige oder aber auch grobe Texte, die Elephant als Inspiration
        zur Texterstellung nutzen kann.
      </p>
      <p>
        Natürlich können Sie uns auch jegliche andere Dateien bereitstellen.
      </p>
      <v-btn
        block
        class="rounded-elephant mt-7 mb-2"
        color="primary"
        :disabled="recap"
        flat
        append-icon="mdi-open-in-new"
        @click="zurSchnittstelle"
        >Zur Schnittstelle</v-btn
      >
    </v-sheet>

    <v-form
      :disabled="recap"
      v-if="form"
      class="my-5 mx-2"
      ref="form"
      v-model="valid"
    >
      <FormSubheader
        title="Tracking Codes"
        help="Bitte teilen Sie uns Ihre Trackingcodes mit, welche auf der alten Website genutzt werden, oder in Zukunft genutzt werden sollen."
      ></FormSubheader>
      <InputGroup>
        <v-select
          hide-details
          flat
          variant="solo-filled"
          rounded
          :items="gaProperty"
          label="Bitte wählen Sie passend aus:"
          v-model="form.gaProperty"
        ></v-select>

        <v-text-field
          class="mt-2"
          hide-details
          flat
          variant="solo-filled"
          rounded
          label="Google Analytics Tracking Code"
          v-model="form.gaTracking"
        ></v-text-field>
        <v-text-field
          class="mt-2"
          hide-details
          flat
          variant="solo-filled"
          rounded
          label="LinkedIn Tracking Code"
          v-model="form.linkedInTracking"
        ></v-text-field>
        <v-text-field
          class="mt-2"
          hide-details
          flat
          variant="solo-filled"
          rounded
          label="Facebook Pixel Tracking Code"
          v-model="form.pixelTracking"
        ></v-text-field>
        <v-text-field
          class="mt-2"
          hide-details
          flat
          variant="solo-filled"
          rounded
          label="Weitere Tracking Codes"
          hint="Bitte mit Komma trennen."
          v-model="form.extraTracking"
        ></v-text-field>
      </InputGroup>
      <OnboardingNavigation v-if="!recap" :form="form" @next="$emit('next')" />
    </v-form>
  </v-container>
</template>

<script>
import { getOnboardingData } from "@/services/onboarding.service";
import VideoWrapper from "@/views/Prozesse/OnBoarding/Steps/VideoWrapper";
import FormSubheader from "@/views/Prozesse/OnBoarding/Steps/FormSubheader";
import InputGroup from "@/views/Prozesse/OnBoarding/Steps/InputGroup";
import ApiService from "@/services/api.service";
import OnboardingNavigation from "@/views/Prozesse/OnBoarding/Steps/OnboardingNavigation";
import Notification from "@/models/notification";

export default {
  name: "StepFour",
  components: { InputGroup, FormSubheader, VideoWrapper, OnboardingNavigation },
  props: {
    recap: Boolean,
  },
  data() {
    return {
      schnittstellenId: null,
      valid: false,
      gaProperty: [
        "Ich habe keine GA Property & will, dass Elephant eine erstellt.",
        "Ich habe keine GA Property, werde diese selbst erstellen und ELephant als Bearbeiter freigeben.",
        "Ich habe bereits eine GA Property & werde Elephant als Bearbeiter freigeben.",
        "Ich möchte kein Google Analytics nutzen.",
      ],
    };
  },
  computed: {
    form() {
      return this.$store.state.processes.onboarding.data;
    },
  },
  created() {
    getOnboardingData().then(() => {
      this.getSchnittstellenId();
    });
  },
  methods: {
    zurSchnittstelle() {
      window.open(
        "https://drive.google.com/drive/u/0/folders/" +
          this.schnittstellenId +
          "#grid"
      );
    },
    getSchnittstellenId() {
      ApiService.post("onboarding/project/get", {
        id: this.$store.state.processes.onboarding.instanceId,
      })
        .then((response) => {
          this.schnittstellenId = response.data.schnittstellenFolderId;
          console.log("Schnittstellen Id: ", this.schnittstellenId);
        })
        .catch(() => {
          this.$store.dispatch(
            "notificationsStore/add",
            new Notification(
              "error",
              "Laden der Schnittstellen Id fehlgeschlagen",
              false
            )
          );
        });
    },
  },
};
</script>

<style scoped></style>
