const messages = {
    en: {
        message: {
            hello: 'Hello, {name}!'
        },
        login:{
            title:'Log into your Account',
            subtitle:'Don\'t have an Account?',
            subtitleLink:'Register here!',
            password:'Enter your password',
            username:'Enter your Username',
            nameRequired:'Name is required',
            nameLength:'Name must be less than 20 characters',
            passwordRequired:'Password is required',


        },
        registration:{
            title:"Let us know who you are",
            subtitle:"Already have an Account?",
            subtitleLink:"Login",
            btnOk:"Register",
            btnCancel:"Cancel",
            username:"Enter Username",
            email:"Enter Email",
            password:"Enter Password",
            nameRequired:'Name is required',
            nameLength:'Name must be less than 20 characters',
            emailRequired:'Email is required',
            emailValid:'Email must be valid',
            passwordRequired:'Password is required',
            passwordCheck:'Min. 8 characters with at least one capital letter, a number and a special character.'
        },
        profile:{
            logout:"Logout",
        }
    },
    de: {
        message: {
            hello: 'Guten Tag, {name}!'
        },
        login:{
            title:'Log into your Account',
            subtitle:'Don\'t have an Account?',
            subtitleLink:'Register here!',
            password:'Enter your password',
            username:'Enter your Username',
            nameRequired:'Name is required',
            nameLength:'Name must be less than 20 characters',
            passwordRequired:'Password is required',


        },
        registration:{
            title:"Let us know who you are",
            subtitle:"Already have an Account?",
            subtitleLink:"Login",
            btnOk:"Register",
            btnCancel:"Cancel",
            username:"Enter Username",
            email:"Enter Email",
            password:"Enter Password",
            nameRequired:'Name is required',
            nameLength:'Name must be less than 20 characters',
            emailRequired:'Email is required',
            emailValid:'Email must be valid',
            passwordRequired:'Password is required',
            passwordCheck:'Min. 8 characters with at least one capital letter, a number and a special character.'
        },
        profile:{
            logout:"Ausloggen",
        }
    }
};

export default messages;
