<template>
  <v-container max-width="100%">
    <div
      class="d-flex h-100 flex-column justify-center align-center"
      style="margin-top: 6rem"
    >
      <DotLottieVue
        style="width: 75%; height: auto"
        autoplay
        loop
        src="https://lottie.host/80dc06a6-cb57-401e-bf32-3f02f0246385/s4uPsRwBOh.json"
      />
      <h2 class="text-center font-weight-bold">
        Unser Servicebereich befindet sich gerade im Bau...
      </h2>
    </div>
  </v-container>
</template>

<script>
import { DotLottieVue } from "@lottiefiles/dotlottie-vue";

export default {
  name: "ServiceWrapper",
  components: {
    DotLottieVue,
  },
};
</script>

<style scoped>
.service-wrapper {
  width: 75%;
  height: auto;
}

@media (max-width: 768px) {
  .service-wrapper {
    width: 100%;
  }
}
</style>
