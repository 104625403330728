<template>
  <v-timeline-item
    class="w-100"
    style="width: 100%"
    dot-color="accent"
    icon="mdi-plus"
    :size="$vuetify.display.smAndDown ? 'small' : 'default'"
  >
    <template v-if="$vuetify.display.mdAndUp" v-slot:opposite>
      <div class="d-flex align-center justify-start">
        <v-btn
          v-tooltip:top="'Datum bearbeiten'"
          icon="mdi-pencil"
          size="small"
          density="comfortable"
          variant="plain"
          class="mr-2"
          @click="editDate = !editDate"
          color="primary"
          small
        />
        <strong v-if="!editDate">Heute</strong>
        <input
          v-else
          class="border px-2 rounded-lg"
          type="date"
          v-model="update.timestamp"
        />
      </div>
    </template>
    <v-card
      flat
      class="pa-3"
      :class="$vuetify.display.smAndDown ? 'w-100' : 'w-25vw'"
      color="backgroundShade"
    >
      <v-text-field
        density="compact"
        v-model="update.title"
        label="Titel"
        color="accent"
        bg-color="background"
        hide-details
        variant="solo"
        class="mb-3"
        flat
      ></v-text-field>
      <v-textarea
        v-model="update.content"
        hide-details
        flat
        bg-color="background"
        color="accent"
        rows="1"
        max-rows="3"
        auto-grow
        label="Beschreibung"
        class="border-background"
        variant="solo"
      ></v-textarea>
      <div
        v-if="$vuetify.display.smAndDown"
        class="d-flex align-center mt-2 justify-start"
      >
        <v-btn
          v-tooltip:top="'Datum bearbeiten'"
          icon="mdi-pencil"
          size="small"
          density="comfortable"
          variant="plain"
          class="mr-1"
          @click="editDate = !editDate"
          color="primary"
          small
        />
        <strong v-if="!editDate">Heute</strong>
        <input v-else type="date" v-model="update.timestamp" />
      </div>
      <v-btn
        block
        append-icon="mdi-send-variant-outline"
        variant="tonal"
        color="accent"
        class="mt-5"
        small
        @click="publishUpdate"
        >{{ $vuetify.display.smAndDown ? "Senden" : "Veröffentlichen" }}</v-btn
      >
    </v-card>
  </v-timeline-item>
</template>

<script>
import ApiService from "@/services/api.service";
import Notification from "@/models/notification";

export default {
  name: "UpdateNew",
  props: {
    projectId: Number,
  },
  data() {
    return {
      editDate: false,
      menu1: false,
      update: {
        timestamp: "",
        title: "",
        content: "",
      },
    };
  },
  methods: {
    onInputTitle(e) {
      this.update.title = e.target.innerText;
    },
    onInputContent(e) {
      this.update.content = e.target.innerText;
    },
    async publishUpdate() {
      try {
        const response = await ApiService.post("project/update/publish", {
          projectId: this.projectId,
          updateItem: this.update,
        });
        console.log(response);
        this.$emit("updateProject", response.data);
        this.update = {
          timestamp: "",
          title: "",
          content: "",
        };
        this.editDate = false;
      } catch (error) {
        this.$store.dispatch(
          "notificationsStore/add",
          new Notification("error", "Veröffentlichung fehlgeschlagen", false)
        );
      }
    },
  },
};
</script>

<style scoped>
.w-25vw {
  width: 25vw;
}
</style>
