import { createRouter, createWebHistory } from "vue-router";
import Login from "./views/Login.vue";
// import Register from "./views/Register.vue";
import ConfirmationToken from "@/views/ConfirmationToken";
import RegisterVerification from "@/views/RegisterVerification";
import Logout from "@/views/Logout";
import OnBoardingWrapper from "@/views/Prozesse/OnBoarding/OnBoardingWrapper";
import One from "@/views/Prozesse/OnBoarding/Steps/One";
// import AdminHome from "@/views/Admin/AdminHome";
import Start from "@/views/Prozesse/OnBoarding/Steps/Start";
import Two from "@/views/Prozesse/OnBoarding/Steps/Two";
import Three from "@/views/Prozesse/OnBoarding/Steps/Three";
import Four from "@/views/Prozesse/OnBoarding/Steps/Four";
import Five from "@/views/Prozesse/OnBoarding/Steps/Five";
import Six from "@/views/Prozesse/OnBoarding/Steps/Six";
import Seven from "@/views/Prozesse/OnBoarding/Steps/Seven";
import OnboardingOverview from "@/views/Prozesse/OnBoarding/OnboardingOverview";
import ProjectWrapper from "@/views/Projekte/ProjectWrapper";
import OnBoardingRecap from "@/views/Prozesse/OnBoarding/OnBoardingRecap";
import AdminProjectList from "@/views/Admin/AdminProjectList";
import ProjectItem from "@/views/Projekte/ProjectItem.vue";
import ServiceWrapper from "@/views/Service/ServiceWrapper";
import store from "./store";
import AnonymousWrapper from "@/views/anonymous/AnonymousWrapper.vue";

function guardMyRoute(to, from, next) {
  let isAuthenticated = store.state.auth.status.loggedIn;
  if (to.name !== "Login" && !isAuthenticated) {
    next("/login?from=" + to.fullPath);
  } else {
    next();
  }
}

function guardAdminRoute(to, next) {
  let isAdmin = store.state.auth.user.roles.includes("ROLE_ADMIN");
  if (to.path.startsWith("/admin") && !isAdmin()) {
    next("/my/projects"); // Umleiten zur Startseite oder einer anderen Route
  } else {
    next(); // Erlauben, zur Route zu navigieren
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/u",
      name: "Anonymous",
      meta: { anonymous: true },
      component: AnonymousWrapper,
      children: [
        {
          path: "reset/password/u/:userId/t/:token",
          name: "Passwort zurücksetzen. Validierung",
          props: true,
          component: () =>
            import("./views/anonymous/ValidateForgotPassword.vue"),
        },
        {
          path: "reset/password/request",
          name: "Passwort zurücksetzen",
          component: () =>
            import("./views/anonymous/RequestPasswordChange.vue"),
        },
      ],
    },
    {
      path: "/admin",
      name: "Admin",
      beforeEnter: guardAdminRoute,
    },

    {
      path: "/service",
      name: "Service",
      beforeEnter: guardMyRoute,
      component: ServiceWrapper,
    },
    {
      path: "/admin/projekte",
      name: "Projektübersicht",
      beforeEnter: guardMyRoute,
      component: AdminProjectList,
    },
    {
      props: true,
      name: "Projekt",
      path: "/admin/projekte/:id/:projectName",
      beforeEnter: guardMyRoute,
      component: ProjectItem,
    },
    {
      path: "/processes/onboarding",
      component: OnboardingOverview,
      beforeEnter: guardMyRoute,
      name: "Prozesse",
    },
    {
      path: "/my/projects",
      component: ProjectWrapper,
      beforeEnter: guardMyRoute,
      name: "Meine Projekte",
    },
    {
      props: true,
      path: "/my/projects/:id",
      component: ProjectItem,
      beforeEnter: guardMyRoute,
      name: "Projekt Details",
    },
    {
      // UserProfile will be rendered inside User's <router-view>
      // when /user/:id/profile is matched
      path: "/onboarding/:id/recap",
      name: "Überblick",
      component: OnBoardingRecap,
      props: true,
    },
    {
      path: "/processes/onboarding/:id",
      component: OnBoardingWrapper,
      // beforeEnter: guardMyRoute,
      name: "Onboarding",
      props: true,
      children: [
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /user/:id/profile is matched
          path: "start",
          name: "Willkommen",
          component: Start,
          props: (route) => ({ user: route.query.q, email: route.query.a }),
        },

        {
          // UserProfile will be rendered inside User's <router-view>
          // when /user/:id/profile is matched
          path: "1of7",
          name: "Schritt 1 von 7",
          component: One,
        },
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /user/:id/profile is matched
          path: "2of7",
          name: "Schritt 2 von 7",
          component: Two,
        },
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /user/:id/profile is matched
          path: "3of7",
          name: "Schritt 3 von 7",
          component: Three,
        },
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /user/:id/profile is matched
          path: "4of7",
          name: "Schritt 4 von 7",
          component: Four,
        },
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /user/:id/profile is matched
          path: "5of7",
          name: "Schritt 5 von 7",
          component: Five,
        },
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /user/:id/profile is matched
          path: "6of7",
          name: "Schritt 6 von 7",
          component: Six,
        },
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /user/:id/profile is matched
          path: "7of7",
          name: "Schritt 7 von 7",
          component: Seven,
        },
      ],
    },
    {
      path: "/",
      name: "Home",

      component: Login,
    },

    {
      path: "/login",
      component: Login,
      name: "Login",
    },

    {
      path: "/logout",
      component: Logout,
    },

    // {
    //   path: "/register",
    //   component: Register,
    // },
    {
      path: "/profile",
      beforeEnter: guardMyRoute,
      name: "Profil",
      // lazy-loaded
      component: () => import("./views/Profile/Profile.vue"),
    },

    {
      path: "/confirm-account",
      beforeEnter: guardMyRoute,
      name: "Account Verifizierung",
      component: ConfirmationToken,
    },
    {
      path: "/successful-registration",
      beforeEnter: guardMyRoute,
      name: "Registrierung erfolgreich",
      component: RegisterVerification,
    },
  ],
});

export default router;
