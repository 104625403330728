<template>
  <v-container class="mt-10" v-if="loading">
    <v-row>
      <v-col cols="12" md="6" v-for="n in 4" :key="n">
        <v-skeleton-loader
          :elevation="2"
          type="paragraph"
          class="py-5"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>

  <v-container v-else>
    <v-tabs
      v-model="tab"
      alignTabs="center"
      color="accent"
      hide-slider
      fixedTabs
      elevation="5"
      class="rounded-lg mx-auto"
      density="compact"
    >
      <v-tab class="mr-1" selectedClass="bg-backgroundShade" value="projects">
        Aktive Projekte
      </v-tab>
      <v-tab
        id="step2"
        class="ml-1"
        selectedClass="bg-backgroundShade"
        value="archivedProjects"
      >
        Abgeschlossene Projekte
      </v-tab>
    </v-tabs>
    <v-window class="overflow-visible" v-model="tab" id="step1">
      <v-window-item value="projects">
        <div
          class="d-flex h-100 flex-column justify-center align-center"
          style="margin-top: 8rem"
          v-if="projects.length === 0"
        >
          <DotLottieVue
            class="w-75 w-md-50 h-auto"
            autoplay
            loop
            src="https://lottie.host/1fe93674-6981-416a-aacd-e588be546c66/eLIrQ9MAKm.json"
          />
          <p class="text-center font-weight-medium">
            Aktuell gibt es keine aktiven Projekte...
          </p>
        </div>

        <v-row
          v-else
          class="d-flex justify-start h-100 mt-2 px-md-5"
          :class="{ 'justify-center': projects.length === 1 }"
        >
          <v-col
            v-for="(project, index) of projects"
            :key="project.id"
            cols="12"
            md="6"
          >
            <v-hover>
              <template v-slot:default="{ isHovering, props }">
                <v-card
                  id="card"
                  class="pa-4 ma-1"
                  color="backgroundShade"
                  v-bind="props"
                  :elevation="isHovering ? 7 : 4"
                  :to="'/my/projects/' + project.id"
                >
                  <ProjectItemCard
                    :data-step="index === 0 ? 'step1' : ''"
                    :project="project"
                  ></ProjectItemCard>
                  <OnboardingItem
                    :id="'onboardingItem-' + index"
                    v-tooltip:bottom="'Onboarding öffnen'"
                    class="bg-backgroundShade border mt-4"
                    adminView
                    :onboardingId="project.onboardingId"
                  ></OnboardingItem>
                </v-card>
              </template>
            </v-hover>
          </v-col>
        </v-row>
      </v-window-item>
      <v-window-item value="archivedProjects">
        <div
          class="d-flex h-100 flex-column justify-center align-center"
          style="margin-top: 8rem"
          v-if="archivedProjects.length === 0"
        >
          <DotLottieVue
            class="w-75 w-md-50 h-auto"
            autoplay
            loop
            src="https://lottie.host/1fe93674-6981-416a-aacd-e588be546c66/eLIrQ9MAKm.json"
          />
          <p class="text-center font-weight-medium">
            Aktuell gibt es keine abgeschlossenen Projekte...
          </p>
        </div>

        <v-row
          v-else
          class="d-flex justify-start h-100 mt-2 px-md-5"
          :class="{ 'justify-center': projects.length === 1 }"
        >
          <v-col
            v-for="project of archivedProjects"
            :key="project.id"
            cols="12"
            md="6"
          >
            <v-hover>
              <template v-slot:default="{ isHovering, props }">
                <v-card
                  :id="'archived-card-' + project.id"
                  class="pa-4 ma-1"
                  color="backgroundShade"
                  v-bind="props"
                  :elevation="isHovering ? 7 : 4"
                  :to="'/my/projects/' + project.id"
                >
                  <ProjectItemCard :project="project"></ProjectItemCard>
                </v-card>
              </template>
            </v-hover>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
    <v-dialog
      v-model="showTour"
      v-if="this.$store.state.auth.user.firstVisit"
      width="auto"
      height="auto"
      class="d-flex justify-end align-start"
      ><v-card class="rounded-elephant h-100 pa-5" max-width="450px"
        ><v-card-title class="text-wrap"
          >Willkommen im Elephant Kundenportal! 👋 </v-card-title
        ><v-card-text
          >Damit Sie sich direkt zurecht finden, haben wir für Sie eine kleine
          Tour vorbereitet.</v-card-text
        ><v-card-actions
          ><v-btn @click="showTour = false">Schließen</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="startIntro"
            variant="tonal"
            append-icon="mdi-arrow-right"
            color="accent"
            >Tour starten</v-btn
          ></v-card-actions
        ></v-card
      ></v-dialog
    >
  </v-container>
</template>

<script>
import ProjectItemCard from "@/views/Admin/ProjectItemCard.vue";
import { DotLottieVue } from "@lottiefiles/dotlottie-vue";
import OnboardingItem from "@/views/Prozesse/OnBoarding/OnboardingItem";
import introJs from "intro.js";

export default {
  name: "AdminProjectList",
  components: {
    ProjectItemCard,
    DotLottieVue,
    OnboardingItem,
  },
  data() {
    return {
      loading: false,
      projects: [],
      archivedProjects: [],
      tab: "projects",
      showTour: true,
    };
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch("project/getMyProjects");
    this.projects = this.$store.state.project.my;
    this.archiveCompletedProjects();
    this.loading = false;
  },
  methods: {
    reloadPage() {
      this.$router.go();
    },
    archiveCompletedProjects() {
      // Filtern der Projekte mit progress = 100
      this.archivedProjects = this.projects.filter(
        (project) => project.progress === 100
      );
      // Entfernen der archivierten Projekte aus der originalen Liste
      this.projects = this.projects.filter(
        (project) => project.progress !== 100
      );
    },
    startIntro() {
      this.showTour = false;
      this.$nextTick(() => {
        const intro = introJs();
        intro.setOptions({
          steps: [
            {
              element: "#step1", // Das Ziel-Element
              title: "Projektübersicht", // Titel des Schritts
              intro:
                "Hier sehen Sie all Ihre aktiven Projekte mit den wichtigsten Informationen.", // Beschreibung des Schritts
            },
            {
              element: "#step2",
              title: "Archivierte Projekte",
              intro: "Hier werden alle abgeschlossenen Projekte angezeigt.",
            },
            {
              element: '[data-step="step1"]',
              title: "Projektkachel",
              intro: "Durch Anklicken gelangen sie zur Projekt Detail Ansicht.",
            },
            {
              element: "#onboardingItem-0",
              title: "Onboarding",
              intro:
                "Hier sehen Sie den Fortschritt Ihres Onboardings. Durch Anklicken gelangen Sie zu den Onboarding Schritten.",
            },
            {
              title: "Tour fortsetzen",
              intro: "Klicken Sie auf Ihr Projekt, um die Tour fortzusetzen!",
            },
          ],
          tooltipClass: "customTooltip",
          buttonClass: "customButton",
          showProgress: false, // Fortschrittsanzeige aktivieren
          showBullets: true, // Keine Navigation-Bullets anzeigen
          exitOnEsc: true, // Schließen mit der Esc-Taste erlauben
          exitOnOverlayClick: false, // Overlay-Klick verhindert Tour-Ende
          disableInteraction: true, // Interaktionen während der Tour deaktivieren
          nextLabel: "Weiter",
          prevLabel: "Zurück",
          doneLabel: "Fortfahren",
          hidePrev: true,
          overlayOpacity: 0.15,
        });
        intro.oncomplete(this.onTourComplete);
        intro.start();
      });
    },
    onTourComplete() {
      if (this.projects.length > 0) {
        const firstProject = this.projects[0];
        this.$router.push("/my/projects/" + firstProject.id);
      } else {
        console.log("Keine Projekte vorhanden");
      }
    },
  },
};
</script>

<style scoped>
#card {
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

#card:hover {
  transform: scale(1.01);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
