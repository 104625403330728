// store/modules/navigation.js
export default {
  namespaced: true,

  state: {
    isOpen: true,
  },

  mutations: {
    TOGGLE_DRAWER(state) {
      state.isOpen = !state.isOpen;
    },
  },

  actions: {
    toggleDrawer({ commit }) {
      commit("TOGGLE_DRAWER");
    },
  },

  getters: {
    isOpen: (state) => state.isOpen,
  },
};
