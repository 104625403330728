import ApiService from "@/services/api.service";
import { NotifiyClient } from "@/models/notification";
import store from "@/store";

export const processes = {
  namespaced: true,
  state: {
    onboarding: {
      currentStep: -1,
      instanceId: null,
      data: null,
      all: [],
      showFinishedDialog: false,
    },
  },
  actions: {
    getMyOnboardings({ commit }) {
      return ApiService.get("onboarding/my/get").then(
        (response) => {
          commit("myOnboardingsSuccess", response.data);
        },
        (error) => {
          console.log(error);
          store.dispatch(
            "notificationsStore/add",
            new NotifiyClient(
              "warning",
              "Fehler",
              false,
              "Deine OnBoardings konnten nicht geladen werden."
            )
          );
        }
      );
    },

    continueOnboarding({ commit }, instanceId) {
      return ApiService.postUnauthorized("onboarding/step/get", {
        id: instanceId,
      }).then(
        (step) => {
          commit("continueOnboardingSuccess", {
            instanceId: instanceId,
            step: step.data,
          });
          store.dispatch("loading/success");
          return Promise.resolve(step);
        },
        (error) => {
          commit("continueOnboardingFailure");
          store.dispatch("loading/error");
          store.dispatch(
            "notificationsStore/add",
            new NotifiyClient(
              "error",
              "Keine gültige Prozess ID",
              false,
              "Fahren Sie mit dem Prozess in Ihrem Profil fort oder klicken Sie auf den Link in der E-Mail"
            )
          );
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    continueOnboardingSuccess(state, step) {
      state.onboarding.currentStep = step.step;
      state.onboarding.instanceId = step.instanceId;
    },
    continueOnboardingFailure(state) {
      state.onboarding.currentStep = -1;
      state.onboarding.instanceId = null;
    },
    myOnboardingsSuccess(state, data) {
      state.onboarding.data = data;
    },
  },
};
