<template>
  <v-app-bar color="background" class="border-b pa-1" flat>
    <template v-slot:prepend>
      <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>
    </template>

    <v-app-bar-title
      v-if="$route.name == 'Projekt Details' && $store.state.project.my"
    >
      <v-select
        class="font-weight-medium"
        :class="{ 'w-25': $vuetify.display.smAndUp }"
        min-width="250px"
        menu-icon="mdi-chevron-down"
        no-data-text="Kein Projekt gefunden"
        hide-details
        flat
        density="compact"
        variant="solo"
        return-object
        :items="$store.state.project.my"
        item-title="name"
        item-value="id"
        v-model="$store.state.project.selected"
      ></v-select>
    </v-app-bar-title>
    <v-app-bar-title
      v-else-if="$route.name === 'Projekt' || $route.name === 'Onboarding'"
      class="font-weight-regular"
      v-text="$route.params.projectName"
    ></v-app-bar-title>
    <v-app-bar-title
      v-else
      class="font-weight-regular"
      v-text="$route.name"
    ></v-app-bar-title>
    <template v-slot:append>
      <v-btn
        icon
        :ripple="false"
        class="elevation-0 mr-3 theme-toggle"
        @click="toggleTheme"
        v-tooltip:left="{
          text: $vuetify.theme.current.dark
            ? 'Light Mode aktivieren'
            : 'Dark Mode aktivieren',
          openDelay: 500,
        }"
      >
        <v-icon :class="{ 'rotate-sun': $vuetify.theme.current.dark }">
          mdi-white-balance-sunny
        </v-icon>
        <v-icon :class="{ 'rotate-moon': $vuetify.theme.current.dark }">
          mdi-weather-night
        </v-icon>
      </v-btn>

      <v-btn
        :disabled="$route.name === 'Profile'"
        @click="$router.push('/profile')"
        v-if="!currentUser"
        icon="mdi-account"
      ></v-btn>
      <v-btn
        :disabled="$route.name === 'Profile'"
        @click="$router.push('/profile')"
        v-else
        icon
        ><v-avatar size="40px"
          ><v-img
            :src="
              currentUser.profilePic
                ? 'data:image/png;base64,' + currentUser.profilePic.file
                : '//ssl.gstatic.com/accounts/ui/avatar_2x.png'
            "
          ></v-img></v-avatar
      ></v-btn>
    </template>
  </v-app-bar>
  <v-progress-linear
    style="position: fixed; z-index: 9999; left: 0; top: 0"
    v-if="
      $store.state.loading.loadingState || $store.state.loading.success.state
    "
    :indeterminate="!$store.state.loading.success.state"
    class="animate__animated"
    :class="{
      animate__fadeOutUp: $store.state.loading.success.animateOut,
      'bg-success': $store.state.loading.success.state == 'success',
      'bg-error': $store.state.loading.success.state == 'error',
    }"
    color="accent"
  ></v-progress-linear>
  <!-- <v-navigation-drawer v-model="drawer" absolute right permanent>
      <v-list nav dense>
        <v-list-item-group active-class="primary--text text--accent-4">
          <v-list-item
            v-for="navItem in $store.state.app.navItems"
            :key="navItem.title"
            @click="gotTo(navItem.target)"
          >
            <v-list-item-title class="text-uppercase text-right">{{
              navItem.title
            }}</v-list-item-title>
          </v-list-item>

          <v-spacer style="height: 25px"></v-spacer>
          <div class="d-flex justify-end">
            <v-btn
              text
              plain
              @click="handleLogout"
              class="align-self-end text-right"
              ><v-icon small>mdi-logout</v-icon> Log out</v-btn
            >
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer> -->
</template>

<script>
import { useTheme } from "vuetify";

export default {
  name: "TopBar",
  setup() {
    const theme = useTheme();
    function toggleTheme() {
      theme.global.name.value = theme.global.current.value.dark
        ? "light"
        : "dark";
    }

    return { toggleTheme };
  },
  data() {
    return {
      drawer: false,
    };
  },

  computed: {
    isAdmin() {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.roles.includes("ROLE_ADMIN")
      );
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    chatPartners() {
      return this.$store.state.chat.currentChatPartners;
    },
    unreadMessages() {
      const sum = this.chatPartners.reduce((accumulator, object) => {
        return accumulator + object.newMessages;
      }, 0);
      return sum;
    },
  },
  methods: {
    toggleDrawer() {
      this.$store.dispatch("navigation/toggleDrawer");
    },
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    handleLogout: function () {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },
    gotTo(target) {
      this.$router.push("/" + target);
    },
  },
};
</script>

<style scoped>
.theme-toggle {
  position: relative;
}

.v-icon {
  position: absolute;
  transition: all 0.7s ease;
}

/* Sonnen-Icon */
.v-icon:nth-child(1) {
  opacity: 1;
  transform: rotate(0);
}

.rotate-sun {
  transform: rotate(90deg) !important;
  opacity: 0 !important;
}

/* Mond-Icon */
.v-icon:nth-child(2) {
  opacity: 0;
  transform: rotate(-90deg);
}

.rotate-moon {
  transform: rotate(0) !important;
  opacity: 1 !important;
}
</style>
