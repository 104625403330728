<template>
  <div class="gradient-background">
    <v-container
      class="py-0"
      :max-width="$vuetify.display.mdAndUp ? '75%' : '100%'"
    >
      <router-view
        v-if="$store.state.processes.onboarding.currentStep != -1"
        @next="getCurrentStep"
      ></router-view>
    </v-container>
  </div>
</template>

<script>
import { NotifiyClient } from "@/models/notification";
export default {
  name: "OnBoardingWrapper",
  props: {
    id: String,
  },
  created() {
    // this.checkOnboardingStep(
    //   this.$store.state.processes.onboarding.currentStep
    // );
    console.log(
      "OnboardingWrapper",
      this.$store.state.processes.onboarding.currentStep
    );
    if (!this.id || this.id === "") {
      this.$store.dispatch(
        "notificationsStore/add",
        new NotifiyClient(
          "error",
          "No Process Instance",
          false,
          "Please reenter the process on your userprofile or over the button in our Email",
          ""
        )
      );
    } else {
      this.getCurrentStep();
    }
  },
  watch: {
    "$store.state.processes.onboarding.currentStep": {
      handler() {
        switch (this.$store.state.processes.onboarding.currentStep) {
          case 0:
            console.log("I am step 0");
            this.$router.push(
              "/processes/onboarding/" +
                this.$store.state.processes.onboarding.instanceId +
                "/start"
            );
            break;
          case 1:
            this.$router.push(
              "/processes/onboarding/" +
                this.$store.state.processes.onboarding.instanceId +
                "/1of7"
            );
            break;
          case 2:
            this.$router.push(
              "/processes/onboarding/" +
                this.$store.state.processes.onboarding.instanceId +
                "/2of7"
            );
            break;
          case 3:
            this.$router.push(
              "/processes/onboarding/" +
                this.$store.state.processes.onboarding.instanceId +
                "/3of7"
            );
            break;
          case 4:
            this.$router.push(
              "/processes/onboarding/" +
                this.$store.state.processes.onboarding.instanceId +
                "/4of7"
            );
            break;
          case 5:
            this.$router.push(
              "/processes/onboarding/" +
                this.$store.state.processes.onboarding.instanceId +
                "/5of7"
            );
            break;
          case 6:
            this.$router.push(
              "/processes/onboarding/" +
                this.$store.state.processes.onboarding.instanceId +
                "/6of7"
            );
            break;
          case 7:
            this.$router.push(
              "/processes/onboarding/" +
                this.$store.state.processes.onboarding.instanceId +
                "/7of7"
            );
            break;

          default:
            this.$router.push(
              "/processes/onboarding/" +
                this.$store.state.processes.onboarding.instanceId
            );
        }
      },
      immediate: true,
    },
  },
  methods: {
    getCurrentStep() {
      this.$store.dispatch("processes/continueOnboarding", this.id);
    },
    checkOnboardingStep(step) {
      console.log("checkOnboarding Step", step);
      const isAdmin = this.$store.state.auth.user.roles.includes("ROLE_ADMIN");
      if (step >= 8 || isAdmin) {
        this.$router.push(
          "/processes/onboarding/" +
            this.$store.state.processes.onboarding.instanceId +
            "/recap"
        );
      }
    },
  },
};
</script>

<style scoped></style>
