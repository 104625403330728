<template>
  <v-container class="mt-10" v-if="loading">
    <v-row
      ><v-col cols="12" md="6" v-for="n in 4" :key="n"
        ><v-skeleton-loader
          :elevation="2"
          type="paragraph"
          class="py-5"
        ></v-skeleton-loader></v-col
    ></v-row>
  </v-container>

  <v-container v-else>
    <v-tabs
      v-model="tab"
      alignTabs="center"
      color="accent"
      hide-slider
      fixedTabs
      elevation="5"
      class="rounded-lg mx-auto"
      density="compact"
    >
      <v-tab class="mr-1" selectedClass="bg-backgroundShade" value="projects"
        >Aktive Projekte</v-tab
      >
      <v-tab
        class="ml-1"
        selectedClass="bg-backgroundShade"
        value="archivedProjects"
        >Abgeschlossene Projekte</v-tab
      >
    </v-tabs>
    <v-window class="overflow-visible" v-model="tab">
      <v-window-item value="projects">
        <div
          class="d-flex h-100 flex-column justify-center align-center"
          style="margin-top: 8rem"
          v-if="projects.length === 0"
        >
          <DotLottieVue
            class="w-75 w-md-50 h-auto"
            autoplay
            loop
            src="https://lottie.host/1fe93674-6981-416a-aacd-e588be546c66/eLIrQ9MAKm.json"
          />
          <p class="text-center font-weight-medium">
            Aktuell gibt es keine aktiven Projekte...
          </p>
        </div>

        <v-row v-else class="d-flex justify-start h-100 mt-2 px-md-5">
          <v-col v-for="project of projects" :key="project.id" cols="12" md="6">
            <v-hover>
              <template v-slot:default="{ isHovering, props }">
                <v-card
                  id="card"
                  class="pa-4 ma-1"
                  color="backgroundShade"
                  v-bind="props"
                  :elevation="isHovering ? 7 : 4"
                  :to="{
                    name: 'Projekt',
                    params: { id: project.id, projectName: project.name },
                  }"
                >
                  <ProjectItemCard :project="project"></ProjectItemCard>
                  <OnboardingItem
                    v-tooltip:bottom="'Onboarding öffnen'"
                    class="bg-backgroundShade border mt-4"
                    adminView
                    :onboardingId="project.onboardingId"
                  ></OnboardingItem>
                </v-card>
              </template>
            </v-hover>
          </v-col>
        </v-row>
      </v-window-item>
      <v-window-item value="archivedProjects"
        ><div
          class="d-flex h-100 flex-column justify-center align-center"
          style="margin-top: 8rem"
          v-if="archivedProjects.length === 0"
        >
          <DotLottieVue
            class="w-75 w-md-50 h-auto"
            autoplay
            loop
            src="https://lottie.host/1fe93674-6981-416a-aacd-e588be546c66/eLIrQ9MAKm.json"
          />
          <p class="text-center font-weight-medium">
            Aktuell gibt es keine abgeschlossenen Projekte...
          </p>
        </div>

        <v-row v-else class="d-flex justify-start h-100 mt-2 px-md-5">
          <v-col
            v-for="project of archivedProjects"
            :key="project.id"
            cols="12"
            md="6"
          >
            <v-hover>
              <template v-slot:default="{ isHovering, props }">
                <v-card
                  id="card"
                  class="pa-4 ma-1"
                  color="backgroundShade"
                  v-bind="props"
                  :elevation="isHovering ? 7 : 4"
                  :to="{
                    name: 'Projekt',
                    params: { id: project.id, projectName: project.name },
                  }"
                >
                  <ProjectItemCard :project="project"></ProjectItemCard>
                </v-card>
              </template>
            </v-hover>
          </v-col> </v-row
      ></v-window-item>
    </v-window>
  </v-container>
  <CreateOnboarding
    type="Projekt"
    @onboarding-created="reloadPage"
    v-show="!loading"
  />
</template>

<script>
import ProjectItemCard from "@/views/Admin/ProjectItemCard.vue";
import { DotLottieVue } from "@lottiefiles/dotlottie-vue";
import CreateOnboarding from "@/views/Admin/CreateOnboarding.vue";
import OnboardingItem from "@/views/Prozesse/OnBoarding/OnboardingItem";

export default {
  name: "AdminProjectList",
  components: {
    ProjectItemCard,
    DotLottieVue,
    CreateOnboarding,
    OnboardingItem,
  },
  data() {
    return {
      loading: false,
      projects: [],
      archivedProjects: [],
      tab: "projects",
    };
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch("project/getAllProjects");
    this.projects = this.$store.state.project.projects;
    this.archiveCompletedProjects();

    this.loading = false;
  },
  methods: {
    reloadPage() {
      this.$router.go();
    },
    archiveCompletedProjects() {
      // Filtern der Projekte mit progress = 100
      this.archivedProjects = this.projects.filter(
        (project) => project.progress === 100
      );
      // Entfernen der archivierten Projekte aus der originalen Liste
      this.projects = this.projects.filter(
        (project) => project.progress !== 100
      );
    },
  },
};
</script>

<style scoped>
#card {
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

#card:hover {
  transform: scale(1.01);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
