<template>
  <v-carousel
    hide-delimiter-background
    rounded="lg"
    :touch="false"
    @click.stop.prevent
    color="white"
    height="auto"
  >
    <v-carousel-item
      v-for="(item, index) in carouselItems"
      :key="index"
      rounded
      class="rounded-lg border-lg"
      :class="{
        'rounded-lg border-lg border-accent border-opacity-100':
          selectedImages.includes(item.name),
      }"
      :src="item.src"
      @click.stop.prevent
      cover
    >
      <div @click.stop class="carousel-content">
        <v-btn
          :ripple="false"
          :disabled="recap"
          :color="selectedImages.includes(item.name) ? 'accent' : 'white'"
          @click.stop="selectItem(item.name)"
          :append-icon="selectedImages.includes(item.name) ? 'mdi-check' : ''"
          class="ma-3"
        >
          {{ selectedImages.includes(item.name) ? "Ausgewählt" : "Auswählen" }}
        </v-btn>
      </div>
      <v-btn
        :ripple="false"
        icon="mdi-arrow-expand-all"
        size="small"
        color="white"
        @click.stop="openDialog(item.src)"
        class="position-absolute top-0 left-0 ma-3"
      >
      </v-btn>
    </v-carousel-item>

    <v-dialog
      v-model="dialog"
      :max-width="$vuetify.display.mdAndUp ? '80%' : '100%'"
      overlay
    >
      <v-card>
        <v-img :src="dialogImage" aspect-ratio="16/9"></v-img>
      </v-card>
    </v-dialog>
  </v-carousel>
  <div
    class="text-right text-disabled mt-1"
    :class="{ 'text-error': showError, bounce: showError }"
  >
    <span> {{ selectedImages.length }}/3 ausgewählt </span>
  </div>
</template>

<script>
import esas from "@/assets/HeroExamples/esas.png";
import mflows from "@/assets/HeroExamples/m-flows.png";
import büchl from "@/assets/HeroExamples/büchl.png";
import CarbonFreed from "@/assets/HeroExamples/CarbonFreed.png";
import fin from "@/assets/HeroExamples/fin.png";
import frankonia from "@/assets/HeroExamples/frankonia.png";
import gominga from "@/assets/HeroExamples/gominga.png";
import Hohenloher from "@/assets/HeroExamples/Hohenloher.png";
import octilla from "@/assets/HeroExamples/octilla.png";

export default {
  props: {
    recap: Boolean,
  },
  emits: ["update:selected-images"],
  data() {
    return {
      selectedImages: [],
      dialog: false,
      dialogImage: "",
      showError: false,
      carouselItems: [
        { name: "esas", src: esas },
        { name: "büchlEntsorgung", src: büchl },
        { name: "carbonFreed", src: CarbonFreed },
        { name: "fin", src: fin },
        { name: "frankonia", src: frankonia },
        { name: "gonminga", src: gominga },
        { name: "hohenloherKaffee", src: Hohenloher },
        { name: "octillaPrime", src: octilla },
        { name: "mflows", src: mflows },
      ],
    };
  },
  methods: {
    selectItem(name) {
      const index = this.selectedImages.indexOf(name);
      if (index === -1) {
        if (this.selectedImages.length < 3) {
          this.selectedImages.push(name);
          this.showError = false;
        } else {
          this.showError = true;
          setTimeout(() => {
            this.showError = false;
          }, 1000); // Reset the error after 1 second
        }
      } else {
        this.selectedImages.splice(index, 1);
        this.showError = false;
      }
      this.$emit("update:selected-images", this.selectedImages);
    },
    openDialog(src) {
      this.dialogImage = src;
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.carousel-content {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1; /* Ensure the button is above the image */
}

.bounce {
  animation: bounce 0.5s ease;
}
@keyframes bounce {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
}
</style>
