import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify.js"; // path to vuetify.js export
import "./scss/global.scss";
import { createI18n } from "vue-i18n";
import messages from "./plugins/VueI18n";
import "hover.css";
import "intro.js/introjs.css";
import "vue-advanced-cropper/dist/style.css";
import { Cropper } from "vue-advanced-cropper";
import VueConfetti from "vue-confetti";

const i18n = createI18n({
  locale: "de",
  messages,
});
const app = createApp(App);

app.use(VueConfetti);
app.use(Cropper);
app.use(i18n);
app.use(router);
app.use(store);
app.use(vuetify);

app.config.globalProperties.$host = "http://localhost:8080";

app.mount("#app");
