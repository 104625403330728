<template>
  <v-timeline-item
    dot-color="accent"
    :size="$vuetify.display.smAndDown ? 'small' : 'default'"
    icon="mdi-arrow-up"
  >
    <template v-if="$vuetify.display.mdAndUp" v-slot:opposite>
      <strong v-on="on" v-bind="attrs"> {{ computedTimestampLong }}</strong>
    </template>
    <strong v-if="$vuetify.display.smAndDown" v-on="on" v-bind="attrs">
      {{ computedTimestampLong }}</strong
    >

    <v-card flat class="bg-backgroundShade rounded">
      <v-card-title style="font-size: 1rem" class="d-flex">
        <span v-text="updateItem.title"></span><v-spacer></v-spacer
        ><v-btn
          v-if="isAdmin"
          @click="deleteUpdate"
          variant="text"
          density="compact"
          color="red-darken-3"
          icon="mdi-trash-can-outline"
          x-small
        ></v-btn
      ></v-card-title>

      <div
        class="mx-4 mb-2 d-flex text-disabled flex-wrap"
        v-html="formattedContent"
      ></div>
    </v-card>
  </v-timeline-item>
</template>

<script>
import moment from "moment";
import ApiService from "@/services/api.service";
import Notification from "@/models/notification";
export default {
  name: "UpdateItem",
  props: {
    updateItem: Object,
    projectId: Number,
  },
  methods: {
    deleteUpdate() {
      let self = this;
      ApiService.post("project/update/delete", {
        projectId: this.projectId,
        updateItem: this.updateItem,
      })
        .then((response) => {
          self.$emit("updateProject", response.data);
        })
        .catch(() => {
          this.$store.dispatch(
            "notificationsStore/add",
            new Notification("error", "Löschen fehlgeschlagen", false)
          );
        });
    },
    linkify(text) {
      const urlPattern =
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
      return text.replace(
        urlPattern,
        '<a href="$1" target="_blank" class="custom-link">$1</a>'
      );
    },
  },
  computed: {
    formattedContent() {
      return this.linkify(this.updateItem.content);
    },
    isAdmin() {
      return this.$store.state.auth.user.roles.includes("ROLE_ADMIN");
    },
    computedTimestamp() {
      moment().locale("de");
      return moment(this.updateItem.timestamp).format("MMM");
    },
    computedTimestampLong() {
      moment.locale("de");
      return moment(this.updateItem.timestamp).format("DD. MMM YYYY");
    },
  },
};
</script>

<style scoped></style>
