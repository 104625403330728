<template>
  <v-container>
    <VideoWrapper
      title="Willkommen!"
      :video-url="require('@/assets/onboardingvideos/Onboarding_Teil1.mp4')"
    >
    </VideoWrapper>

    <p class="">
      Herzlich Willkommen! Wir freuen uns sehr auf unsere Zusammenarbeit und
      gemeinsame Reise. Dieses Onboarding besteht aus einer <b>Videoreihe</b>,
      in der unser Co-Founder Jonah alle relevanten Punkte unserer
      Zusammenarbeit klärt. Wir fragen Ihre Wünsche, Anforderungen und sonstige
      Details ab. So können wir Ihnen bestmöglich zur Seite stehen.
      <b>Viel Erfolg!</b>
    </p>
    <div class="d-flex justify-end">
      <v-btn
        color="primary"
        :block="$vuetify.display.smAndDown"
        append-icon="mdi-arrow-right"
        class="my-7"
        @click="checkStep"
        >Beginnen</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import { nextStep } from "@/services/onboarding.service";
import VideoWrapper from "@/views/Prozesse/OnBoarding/Steps/VideoWrapper";

export default {
  name: "StepOne",
  components: { VideoWrapper },
  methods: {
    checkStep() {
      //TODO Go to Server and check step 1 if good increase activeStep
      nextStep().then(() => {
        this.$emit("next");
      });
    },
  },
};
</script>

<style scoped></style>
