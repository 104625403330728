export const app = {
  namespaced: true,
  state: {
    welcome: {
      page: 1,
      pages: 4,
      progress: 1,
    },
    navItems: [
      { title: "Profile", target: "profile" },
      { title: "Prozesse", target: "Prozesse" },
      { title: "Projekte", target: "Projekte" },
      { title: "Service", target: "Service" },
    ],
    initialHistory: 0,
  },
};
