import { createStore } from "vuex";
import { project } from "@/store/project.module.js";
import { auth } from "./auth.module";
import { loading } from "./loading.module";
import { notificationsStore } from "@/store/notifications.module";
import { processes } from "@/store/processes.module";
import { app } from "./app.module";
import navigation from "./nav.module";

export default createStore({
  modules: {
    navigation,
    auth,
    loading,
    processes,
    notificationsStore,
    app,
    project,
  },
});
