<template>
  <v-card flat class="bg-backgroundShade">
    <v-card-title class="d-flex justify-space-between align-center">
      <span
        >{{ project.name }}
        <span class="text-disabled text-subtitle-1 ml-2"
          >#{{ project.id }}</span
        ></span
      >

      <v-chip color="accent" size="small">{{
        project.typ
      }}</v-chip></v-card-title
    >
    <v-card-subtitle class="my-1 d-flex">
      <v-icon class="mr-2" size="small">{{
        project.progress === 100
          ? "mdi-calendar-check-outline"
          : "mdi-calendar-outline"
      }}</v-icon>
      <div v-if="project.estimatedProjectEnd">
        <span v-if="project.progress != 100"
          >{{ endDate }} (in {{ daysRemaining }} Tagen)</span
        ><span v-else>Platzhalter Abschlussdatum</span>
      </div>
      <div v-else>-</div>
    </v-card-subtitle>
    <v-card-text>
      <div class="d-flex justify-center align-center ga-5">
        <v-progress-linear
          :model-value="project.progress"
          rounded
          color="primary"
          height="12"
        ></v-progress-linear
        ><strong style="font-size: 1.2rem"
          >{{ Math.ceil(project.progress) }}%</strong
        >
      </div></v-card-text
    >
  </v-card>
</template>

<script>
export default {
  name: "ProjectItemCard",
  props: {
    project: Object,
  },
  computed: {
    endDate() {
      const estimatedEndDate = new Date(this.project.estimatedProjectEnd);
      const day = String(estimatedEndDate.getDate()).padStart(2, "0");
      const month = String(estimatedEndDate.getMonth() + 1).padStart(2, "0"); // Monate sind 0-basiert
      const year = estimatedEndDate.getFullYear();

      return `${day}.${month}.${year}`;
    },
    daysRemaining() {
      const estimatedEndDate = new Date(this.project.estimatedProjectEnd);
      const today = new Date();
      const timeDiff = estimatedEndDate - today;
      const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Millisekunden zu Tagen umrechnen

      return daysDiff;
    },
  },
};
</script>

<style scoped></style>
