<template>
  <div></div>
</template>

<script>
export default {
  name: "LogoutPage",
  created() {
    this.$store.dispatch("auth/logout");
    this.$router.push("/login");
  },
};
</script>

<style scoped></style>
