import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        updateLocalUser({commit},user){
            commit('updateLocalUser',user)
        },
        login({ commit }, user) {

            return AuthService.login(user).then(
                user => {
                    commit('loginSuccess', user.data);
                    return Promise.resolve(user.data);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        loginCognito({ commit }, tokenObject) {

            return AuthService.loginCognito(tokenObject).then(
                user => {
                    commit('loginSuccess', user);

                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
        register({ commit }, user) {
            return AuthService.register(user).then(
                user => {
                    commit('loginSuccess', user.data);
                    return Promise.resolve(user);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        refreshLogin({commit},token) {
            return AuthService.refreshToken(token).then(
                (user) => {
                    commit('loginSuccess',user);
                    return Promise.resolve(user);
                },
                (error) => {
                    console.log("Error refreshing token", error.response.data);
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
    },
    mutations: {
        updateLocalUser(state,user){
            localStorage.setItem('user', JSON.stringify(user));
            /**if(user.roles && user.roles.length>0 && user.roles[0].id){
                let userRoles=[];
                for(let r of user.roles){
                    userRoles.push(r.name);
                }
                user.roles=userRoles;
            }*/
            state.user=user;
        },
        loginSuccess(state, user) {
            console.log("State",user);
            state.status.loggedIn = true;
            localStorage.setItem('user', JSON.stringify(user));
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        }
    }
};